<template>
  <fragment>
    <div
      class="pageWrapper"
      :style="[showAddWidget ? { paddingRight: '500px' } : null]"
    >
      <div class="topbar">
        <div style="display: flex; justify-content: space-between">
          <div>
            <ShopPicker />
            <DatePicker
              v-model="dateRange"
              value-type="MM-DD-YYYY"
              :range="true"
              :default-value="new Date().setMonth(new Date().getMonth() - 1)"
              :range-separator="' to '"
              placeholder="Specify the date"
            />
          </div>
          <!-- TODO - Add widgets -->
          <!-- <MainButton title="Add widgets" :clickFunction="openAddWidget" /> -->
        </div>
        <SaveDiscardMenu
          :saveChanges="save"
          :isChanged="isChanged"
          :discardChanges="discard"
          :title="`Dashboard`"
        />
      </div>
      <div id="statisticsWrapper" v-if="populatedLayout">
        <GridLayout
          :layout="populatedLayout"
          @layout-ready="populateLayout"
          :col-num="12"
          :row-height="30"
          :is-draggable="isEditing"
          :is-resizable="false"
          :responsive="true"
          :vertical-compact="false"
          :margin="[10, 10]"
          :use-css-transforms="true"
        >
          <fragment v-for="item in populatedLayout" :key="item.id">
            <WidgetWrapper
              @long-press-start="onLongPressStart"
              v-long-press="700"
              :item="item"
              :isEditing="isEditing"
              :dateRange="dateRange"
            />
          </fragment>
        </GridLayout>
      </div>
    </div>
    <AddWidgetSidebar v-if="showAddWidget" :addWidget="addWidget" />
  </fragment>
</template>

<script>
import LongPress from 'vue-directive-long-press'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { GridLayout } from 'vue-grid-layout';
import axios from 'axios'

import SaveDiscardMenu from '@/components/SaveDiscardMenu.vue'
import ShopPicker from '@/components/ShopPicker.vue'
import MainButton from '@/layout/MainButton';

import WidgetWrapper from '../../components/statistics/WidgetWrapper.vue';
import widgetJson from './../../components/statistics/widgets.json'
import AddWidgetSidebar from '../../components/statistics/AddWidgetSidebar.vue';

export default {
  directives: {
    'long-press': LongPress
  },
  components: {
    ShopPicker,
    DatePicker,
    GridLayout,
    MainButton,
    WidgetWrapper,
    SaveDiscardMenu,
    AddWidgetSidebar,
  },
  data() {
    return {
      dateRange: null,
      isEditing: false,
      widgets: widgetJson,
      populatedLayout: undefined,
      basePopulatedLayout: undefined,
      showAddWidget: false,
    }
  },
  created() {
    this.getWidgets()
  },
  computed: {
    isChanged() {
      if (!(JSON.stringify(this.basePopulatedLayout) == JSON.stringify(this.populatedLayout)) || this.isEditing) {
        return true
      }
      return false
    },
  },
  methods: {
    openAddWidget() {
      this.showAddWidget = true
    },
    addWidget() {
      this.isEditing = true
    },
    getWidgets() {
      axios.get('widgets/getWidgets')
        .then(res => {
          this.populatedLayout = res.data.map(item => {
            return { ...item, ...this.widgets[item.widget] }
          })
        })
        .catch(err => {
          console.log(err);
        })
    },
    populateLayout() {
      this.basePopulatedLayout = JSON.parse(JSON.stringify(this.populatedLayout))
    },
    onLongPressStart() {
      this.isEditing = true
    },
    save() {
      axios.post('widgets/updateWidgets', {
        populatedLayout: this.populatedLayout
      })
        .then(res => {
          this.basePopulatedLayout = JSON.parse(JSON.stringify(this.populatedLayout))
          this.isEditing = false
        })
        .catch(err => {
          console.log(err);
        })
    },
    discard() {
      this.isEditing = false
      this.populatedLayout = JSON.parse(JSON.stringify(this.basePopulatedLayout))
    }
  }
}
</script>

<style scoped>
.topbar {
  display: flex;
  flex-direction: column;
}

.statisticsWrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}

.statisticsWrapper > div {
  flex: 1;
  flex-basis: auto;
  margin: 10px;
  width: 500px;
}

::v-deep .vue-grid-item {
  touch-action: none;
}

::v-deep .vue-grid-item.vue-grid-placeholder {
  background: rgb(163, 163, 163) !important;
  border-radius: 5px;
}

::v-deep .mx-datepicker-range {
  cursor: pointer;
  width: 210px;
}

::v-deep .mx-input {
  height: 27px !important;
  top: 0px;
  position: relative;
  cursor: pointer;
  font-size: 10pt;
  padding: 5px 10px;
  border-radius: 5px;
  margin-right: 10px;
  -webkit-box-shadow: inset 0px 0px 2px 1px rgba(219, 219, 219, 1);
  -moz-box-shadow: inset 0px 0px 2px 1px rgba(219, 219, 219, 1);
  box-shadow: inset 0px 0px 2px 1px rgba(219, 219, 219, 1);
  border: none;
}

.mx-datepicker-range {
  cursor: pointer;
  width: 210px;
}
</style>