<template>
  <div class="whiteBox">
    <h4 v-if="!dateRange">Orders today</h4>
    <h4 v-else>Orders</h4>
    <p class="bigNumber">{{ amountOrders }}</p>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  props: ['dateRange'],
  created() {
    this.fetchData()
  },
  data() {
    return {
      amountOrders: undefined
    }
  },
  computed: {
    shops() {
      return this.$store.state.selectedShops
    },
  },
  watch: {
    shops() {
      this.fetchData()
    },
    dateRange() {
      this.fetchData()
    }
  },
  methods: {
    fetchData() {
      axios.post('statistics/getAmountOrders', {
        shops: this.shops,
        dateRange: this.dateRange
      })
        .then(res => {
          this.amountOrders = res.data.amountOrders

        })
        .catch(err => {
          console.log(err);
        })
    },
  }
}
</script>

<style scoped src="@/assets/css/widgets.css"></style>
