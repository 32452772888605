<template>
  <GridItem :x="item.x" :y="item.y" :w="item.w" :h="item.h" :i="item.i">
    <div
      :class="
        'widget ' + (isEditing && (item.i % 2 === 0 ? 'shake2' : 'shake1'))
      "
      :style="{
        'animation-duration':
          Math.floor(Math.random() * (0.5 - 0.3)) + 0.3 + 's',
      }"
    >

      <!-- TODO - Add widgets -->
      <!-- <ion-icon
        class="removeIcon"
        name="remove-outline"
        v-if="isEditing"
      ></ion-icon> -->
      <Widget :is="item.widget" :dateRange="dateRange"/>
    </div>
  </GridItem>
</template>

<script>
import { GridItem } from 'vue-grid-layout';

import Income from './widgets/Income.vue'
import AmountOrdersChart from './widgets/AmountOrdersChart.vue'
import AmountOrders from './widgets/AmountOrders.vue'


export default {
  props: ['widget', 'item', 'isEditing', 'dateRange'],
  components: {
    GridItem,
    Income,
    AmountOrders,
    AmountOrdersChart,
  },
}
</script>

<style scoped>
.removeIcon {
  background: rgb(177, 177, 177);
  color: white;
  padding: 1px;
  border-radius: 20px;
  float: right;
  font-size: 17pt;
  position: absolute;
  top: -7px;
  right: -6px;
  z-index: 10;
}

.widget {
  transition: 0.7s;
  transition-timing-function: linear;
  cursor: default;
  height: 100%;
  width: 100%;
}

.widget:active {
  transform: scale(1.04);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.shake1 {
  animation-name: keyframes1;
  animation-iteration-count: infinite;
  transform-origin: 50% 10%;
}

.shake2 {
  animation-name: keyframes2;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  transform-origin: 30% 5%;
}

.shake2:active,
.shake1:active {
  transition: 0s !important;
  transform: scale(1) !important;
  box-shadow: none !important;
}

@keyframes keyframes1 {
  0% {
    transform: rotate(-0.2deg);
    animation-timing-function: ease-in;
  }

  50% {
    transform: rotate(0.6deg);
    animation-timing-function: ease-out;
  }
}

@keyframes keyframes2 {
  0% {
    transform: rotate(0.3deg);
    animation-timing-function: ease-in;
  }

  50% {
    transform: rotate(-0.6deg);
    animation-timing-function: ease-out;
  }
}
</style>