<template>
  <div class="whiteBox chartBox">
    <h4>Amount orders</h4>
    <br />
    <BarChart
      v-if="amountOrdersChartData"
      :height="300"
      :chartData="datacollection"
      :options="chartOptions"
    />
  </div>
</template>

<script>
import axios from 'axios'
import BarChart from '../../charts/BarChart.vue'
import { formatDateWithDayAndMonthName } from '../../../globalFunctions/date'


export default {
  props: ['dateRange'],
  created() {
    this.fetchData()
  },
  data() {
    return {
      amountOrdersChartData: undefined
    }
  },
  components: {
    BarChart,
  },
  computed: {
    shops() {
      return this.$store.state.selectedShops
    },
  },
  watch: {
    shops() {
      this.fetchData()
    },
    dateRange() {
      this.fetchData()
    }
  },
  methods: {
    formatDateWithDayAndMonthName,
    fetchData() {
      axios.post('statistics/getAmountOrdersChart', {
        shops: this.shops,
        dateRange: this.dateRange
      })
        .then(res => {
          this.amountOrdersChartData = res.data
          this.setChartOptions()
          this.fillData()
        })
        .catch(err => {
          console.log(err);
        })
    },
    setChartOptions() {
      this.chartOptions = {
        scales: {
          xAxes: [{
            gridLines: {
              display: false
            },
            ticks: {
              display: false
            }
          }],
        },
        responsive: true,
        maintainAspectRatio: false,
        cutoutPercentage: 80,
        legend: {
          display: false
        },
        tooltips: {
          enabled: true,
          mode: 'index'
        },
        hover: {
          mode: true
        }
      }
    },
    fillData() {
      this.datacollection = {
        labels: this.amountOrdersChartData.map(el => {
          return this.formatDateWithDayAndMonthName(el.date)
        }),
        datasets: [
          {
            backgroundColor: '#1f5c94',
            data: this.amountOrdersChartData.map(el => {
              return el.amountOrders
            })
          },
        ]
      }
    }
  }
}
</script>

<style scoped src="@/assets/css/widgets.css"></style>
