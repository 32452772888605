<template>
  <div class="whiteBox">
    <h4 v-if="!dateRange">Income today</h4>
    <h4 v-else>Income</h4>
    <p class="bigNumber">{{ totalSale }}<span class="currency">kr</span></p>
  </div>
</template>

<script>
import axios from 'axios'
import { GridItem } from 'vue-grid-layout';

export default {
  props: ['dateRange'],
  created() {
    this.fetchData()
  },
  components: {
    GridItem,
  },
  data() {
    return {
      totalSale: undefined,
      size: { "x": 0, "y": 0, "w": 2, "h": 3 },
    }
  },
  computed: {
    shops() {
      return this.$store.state.selectedShops
    },
  },
  watch: {
    shops() {
      this.fetchData()
    },
    dateRange() {
      this.fetchData()
    }
  },
  methods: {
    fetchData() {
      axios.post('statistics/getTotalIncome', {
        shops: this.shops,
        dateRange: this.dateRange
      })
        .then(res => {
          this.totalSale = res.data.totalIncome
        })
        .catch(err => {
          console.log(err);
        })
    },
  }
}
</script>

<style scoped src="@/assets/css/widgets.css"></style>
<style lang="scss" scoped>
  .whiteBox {
    max-height: 110px;
  }
</style>