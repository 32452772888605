<template>
  <div class="addWidgetSidebar">
    <MainButton title="Add widget" :clickFunction="() => {addWidget('income')}" />
    <Income />
  </div>
</template>

<script>
import Income from './widgets/Income.vue'
import AmountOrdersChart from './widgets/AmountOrdersChart.vue'
import AmountOrders from './widgets/AmountOrders.vue'
import WidgetWrapper from './WidgetWrapper.vue'
import { GridLayout, GridItem } from 'vue-grid-layout';
import MainButton from '@/layout/MainButton'

export default {
  props: ['addWidget'],
  components: {
    GridLayout,
    GridItem,
    Income,
    AmountOrders,
    AmountOrdersChart,
    WidgetWrapper,
    MainButton,
  },
  data() {
    return {
      widgets: [
        { "i": 46, "widget": "income", "x": 0, "y": 0, "h": 3, "w": 2 },
        { "i": 47, "widget": "amount-orders", "x": 0, "y": 3, "h": 3, "w": 2 },
        { "i": 48, "widget": "amount-orders-chart", "x": 2, "y": 0, "h": 10, "w": 5 }
      ]
    }
  }
}
</script>

<style>
.addWidgetSidebar {
  position: fixed;
  right: 0;
  top: 0;
  width: 500px;
  background: #f7f7f7;
  height: 100%;
  padding: 50px;
  padding-top: 80px;
  box-shadow: 0px 0px 4px -2px #7d7d7d;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}
</style>